<template>
  <div><span v-if="beforeText !== ''" class="me-1">{{ beforeText }}</span>{{ ellapsedTime }}</div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default {
  name: 'SessionTimer',
  props: {
    startTime: {
      type: String,
      required: true,
    },
    beforeText: {
      type: String,
      default: '',
    },
  },
  computed: {
    ellapsedTime() {
      const { start, now } = this;
      const s = now.diff(start, 's');
      if (s < 86400) return new Date(s * 1000).toISOString().substr(11, 8);
      return [
        Math.floor(s / 60 / 60 / 24),
        Math.floor(s / 60 / 60) % 24,
        Math.floor(s / 60) % 60,
        s % 60,
      ].map((val) => (val < 10 ? `0${val}` : `${val}`)).join(':');
    },
  },
  data() {
    return {
      timer: null,
      start: null,
      now: dayjs(),
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      this.now = dayjs();
    }, 1000);
  },
  created() {
    this.start = dayjs.utc(this.startTime);
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>
